#header {
    width: 100%;
    background: rgba(255, 255, 255, 0.05);

    #header-mobile {
        display: none;
        padding: 20px;
        font-size: 12px;

        .button {
            font-size: 12px;
        }

        >ul {
            padding: 0;
            margin: 0;
            display: flex;

            >li {
                >a {
                    color: white;
                    text-decoration: none;

                    transition: 0.2s ease;
                    &:hover {
                        color: #ffaa00;
                    }
                }
            }
        }
        
        .top {
            list-style-type: none;
            justify-content: space-between;
            height: 40px;

            >.logo {
                background-image: url("../images/logo_small.png");
                background-repeat: no-repeat;
                background-size: contain;
                width: 50px;
            }

            &.connected {
                justify-content: center;

                >.logo {
                    background-image: url("../images/logo.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    width: 100%;
                }
            }

            >.logo-connected {
                background-image: url("../images/logo.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 100% 50%;
                width: 200px;
                height: 60px;
            }
        }

        .bottom {
            justify-content: space-between;
            list-style-type: none;
            flex-direction: row-reverse;
            padding: 10px;
            white-space: nowrap;    
            margin-top: 20px;
        }
    }

    #header-desktop {
        height: 80px;
        padding-left: 50px;
        padding-right: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        >.logo {
            background-image: url("../images/logo.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 100% 50%;
            width: 200px;
            height: 60px;
        }

        >ul {
            padding: 0;
            list-style: none;
            display: flex;
            font-size: 14px;
            line-height: 35px;
            flex-direction: row;
            margin-left: auto;
            align-items: center;
            justify-content: center;
            
            text-transform: uppercase;

            >li {
                margin-left: 40px;

                >a {
                    color: white;
                    text-decoration: none;

                    transition: 0.2s ease;
                    &:hover {
                        color: #ffaa00;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    #header {
        #header-desktop {
            display: none;
        }

        #header-mobile {
            display: block;
        }
    }
}