@font-face {
    font-family: "FONT";
    src: url("TacticSansExtExd-Ult.woff2");
}

@keyframes spin {
	0% {transform: rotate(0deg); }
	100% {transform: rotate(360deg); }
}

.loader {
    margin: auto;
	width: 25px;
	height: 25px;
  
    border: 0.15em solid rgba(15,15,25,1);
    border-right: 0.15em solid rgba(15,15,25,1);
    border-left: 0.15em solid rgba(15,15,25,1);
    border-radius: 5px;
  
    animation: spin 1s ease infinite;
}

* {
    box-sizing: border-box;
}

html {
    width: 100%;
    height: 100%;
}

body {
    font-family: "FONT";
    letter-spacing: 0.345638px;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: white;

    background-image: url("../images/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#root {
    height: 100%;
}

.highlight {
    color: #ffaa00;
}

@media only screen and (max-width: 600px) {
    body {
        background-position: 0 0;
    }
}