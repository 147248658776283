#center {
    width: 55%;
    height: 55%;
    margin: auto;
    position: relative;
    top: 10%;
    background: linear-gradient(rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.8)), url("../images/center.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px lime;

    #center-top {
        height: 80%;

        #claimable {
            margin: auto;
            max-width: 400px;
            position: relative;
            top: 10%;

            >h2 {
                padding-top: 20px;
                font-weight: 100;
            }

            >ul {
                font-size: 12px;
                list-style: none;
                padding: 0;
            }
        }

        #wallet-input {
            margin-top: 10px;
            margin-bottom: 30px;
            font-family: "FONT";
            font-size: 10px;
            color: white;

            width: 100%;
            padding: 10px;
            border: 2px solid #ffaa00;
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.5);

            &:focus {
                outline: none;
                border: 2px solid white;
            }
        }
    }

    #center-bottom {
        height: 20%;

        #claim-result {
            margin-top: 10px;
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 800px) {
    #center {
        width: 95%;
        top: 5%;
        
        #center-top {
            #claimable {
                max-width: 280px;
            }
        }
    }
}