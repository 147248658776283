.button {
    cursor: pointer;
    min-width: 140px;
    font-family: "FONT";
    color: rgba(15,15,25,1);
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    background-color: #ffaa00;
    border-radius: 8px;
    border: 2px solid #ffaa00;
    text-transform: uppercase;
}

.button.disabled {
    color: gray;
    cursor: not-allowed;
}

.button.small {
    min-width: 70px;
    height: 35px;
    padding: 5px;
    border-radius: 0;
}

.button:hover {
    color: #ffaa00;
    background-color: rgba(0,0,0,0);
}

.button:hover > .loader {
    border-color: #ffaa00;
}
